import { template as template_2f91ba828cb8446fa6d4276db6e7da83 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2f91ba828cb8446fa6d4276db6e7da83(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
