import { template as template_9b6c4789f9b94b30bc1c24e2e7e647d6 } from "@ember/template-compiler";
const FKText = template_9b6c4789f9b94b30bc1c24e2e7e647d6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
