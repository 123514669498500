import { template as template_a698aeddc00044e0a621a558321cab65 } from "@ember/template-compiler";
const FKLabel = template_a698aeddc00044e0a621a558321cab65(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
